import ImgPlacholder from "../../images/round-profile-img.png"

export const personnelData = [
  {
    personImg: ImgPlacholder,
    personName: "Bill Baker",
    personPosition: "Co-Founder & CEO",
    // personalDescription:
    //   "Bill is deeply insightful technology-turned-media entrepreneur who truly personifies the ‘think different’ concept.",
    personLinkedIn: "https://www.linkedin.com/in/bill-bakerbb/",
  },
  {
    personImg: ImgPlacholder,
    personName: "Dr. Joan Salge Blake, EdD",
    personPosition: "Co-Founder & Chief Nutrition Officer",
    // personalDescription: "This is yet to be updated...",
    personLinkedIn: "https://www.linkedin.com/in/joansalgeblake/",
  },
  {
    personImg: ImgPlacholder,
    personName: "Matthew LaSpisa",
    personPosition: "Executive Chef/NuQ Kitchen",
    // personalDescription: "This is yet to be updated...",
    personLinkedIn: "https://www.linkedin.com/in/matthew-laspisa-91b48882/",
  },
  {
    personImg: ImgPlacholder,
    personName: "Subbu Ponnuswamy",
    personPosition: "Co-Founder & Advisor/Technology and Patents",
    // personalDescription: "This is yet to be updated...",
    personLinkedIn: "https://www.linkedin.com/in/subbuponnuswamy/",
  },
  {
    personImg: ImgPlacholder,
    personName: "Megan Kent",
    personPosition: "Advisor, Branding and Marketing",
    // personalDescription: "This is yet to be updated...",
    personLinkedIn: "https://www.linkedin.com/in/megankent/",
  },
  {
    personImg: ImgPlacholder,
    personName: "SB (Confidential)",
    personPosition: "System Architect & CTO",
    // personalDescription: "This is yet to be updated...",
    // personLinkedIn: "/",
  },
]
