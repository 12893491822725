import React, { useState } from "react"

const Personnel = ({
  personImg,
  personName,
  personPosition,
  children,
  personLinkedIn,
}) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <div className="personnel-accordion-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-6 mx-lg-0 mx-md-0 mx-auto pl-lg-0 pl-md-0 pl-auto align-self-center text-lg-left text-md-left-text-center">
              <img src={personImg} alt="Person" className="img-fluid" />
            </div>
            <div className="col-lg-10 col-md-10 col-12 align-self-center mt-lg-0 mt-md-0 mt-4">
              <div
              // className={`trigger-content ${isOpen ? "open" : ""}`}
              // onClick={() => setOpen(!isOpen)}
              >
                <a href={personLinkedIn} target="_blank">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h3
                        className="font-weight-bold personnel-name"
                        style={{ paddingBottom: "0.5rem" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: personName,
                          }}
                        />
                      </h3>
                      <div className="personnel-position">
                        {/* {personPosition} */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: personPosition,
                          }}
                        />
                      </div>
                    </div>
                    <div className="align-self-end">
                      <svg
                        className={`${isOpen ? "rotate-arrow" : ""}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.827"
                        height="28.047"
                        viewBox="0 0 15.827 28.047"
                      >
                        <g
                          id="Component_43_1"
                          data-name="Component 43 – 1"
                          transform="translate(2 2)"
                        >
                          <g
                            id="Component_42"
                            data-name="Component 42"
                            transform="translate(16.18 -8.85) rotate(90)"
                          >
                            <path
                              id="Union_26"
                              data-name="Union 26"
                              d="M12.024,0,24.047,11.827Zm0,0L0,11.827Z"
                              transform="translate(8.85 4.353)"
                              fill="none"
                              stroke="#00ccb9"
                              stroke-linejoin="round"
                              stroke-width="4"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div> */}
      </div>
    </>
  )
}

export default Personnel
