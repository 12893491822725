import React from "react"
import WebriQForm from "@webriq/gatsby-webriq-form"

const ContactForm = () => {
  return (
    <>
      <WebriQForm
        method="POST"
        data-form-id="52e51e39-13f4-4254-827e-0d0d0eb0d12c"
        name="Contact Form"
        className="form-newsletter"
        data-thankyou-url="/thank-you"
      >
        <div className="form-row">
          <div className="col-lg-6 col-md-6 mb-3">
            {/* <label htmlFor="name">Enter Your Name</label> */}
            <input
              className="w-100 form-input"
              type="text"
              name="Name"
              id="name"
              placeholder="Name"
            />
            {/* <small className="text-dark form-text mt-2">
                We will never share your email with anyone else.
              </small> */}
          </div>

          <div className="col-lg-6 col-md-6 mb-3">
            {/* <label htmlFor="email_address">Enter Your Email Address</label> */}
            <input
              className="w-100 form-input"
              type="email"
              name="Email Address"
              id="email_address"
              placeholder="Email"
            />
            {/* <small className="text-dark form-text mt-2">
                We will never share your email with anyone else.
              </small> */}
          </div>

          <div className="col-lg-12 mb-3">
            {/* <label htmlFor="name">Message</label> */}
            <textarea
              type="text"
              name="Message"
              id="message"
              className="w-100 form-textarea"
              placeholder="Message"
              rows="3"
            />
          </div>

          <div className="col-12">
            <div className="form-group my-0">
              <div className="webriq-recaptcha" />
            </div>
          </div>

          <div className="col-12 text-lg-right text-md-right text-center">
            <div className="form-group">
              <button className="submit-btn" type="submit">
                Submit Now
              </button>
            </div>
          </div>
        </div>
      </WebriQForm>
    </>
  )
}

export default ContactForm
