import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

// import { StaticImage } from "gatsby-plugin-image"

import { personnelData } from "../components/personnel/personnel-data"
import Personnel from "../components/personnel/personnel"

import ContactForm from "../components/form/contact-form"

const TheCompany = ({ location }) => {
  return (
    <>
      <Layout location={location}>
        <Seo title="The Company" />
        <section className="the-company-wrapper page-content">
          <article>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <h2 className="title text-d-cyan pb-0">
                    Leading the <span>Wellness Revolution</span>
                  </h2>
                  <p className="lead mt-4">
                    Created by experts in nutrition, deep tech developers and
                    rich data specialists, <b>NuQ&#8482;</b> is a purpose-driven
                    start-up deeply dedicated to{" "}
                    <b>Disrupting Un-Healthy Lifestyles™</b>.
                  </p>
                </div>
                {/* <div className="col-lg-6 align-self-center">
                  <StaticImage
                    placeholder="dominantColor"
                    className="img-fluid"
                    src="../images/contact-us-img.png"
                    alt="The Company"
                  />
                </div> */}
              </div>
            </div>
          </article>
          <article>
            <div className="container">
              <div className="row mt-0 mt-0">
                <div className="col-lg-7 mt-lg-4 mt-md-4 mt-sm-4 mt-4">
                  <h2 className="title text-d-cyan pb-0 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                    Leadership
                  </h2>
                  <div className="wrapper pt-lg-3 pt-md-3 pt-3">
                    {personnelData.map(info => (
                      <Personnel
                        personImg={info.personImg}
                        personName={info.personName}
                        personPosition={info.personPosition}
                        personLinkedIn={info.personLinkedIn}
                      >
                        <div className="container">
                          <div className="row">
                            {/* <div className="col-lg-10 col-md-10 ml-lg-auto ml-md-auto py-5">
                              {info.personalDescription}
                            </div> */}
                          </div>
                        </div>
                      </Personnel>
                    ))}
                  </div>
                  {/* <Personnel>Hello Testing only</Personnel>
                  <Personnel>Hello Testing only</Personnel>
                  <Personnel>Hello Testing only</Personnel>
                  <Personnel>Hello Testing only</Personnel> */}
                </div>
                <div className="col-lg-4 ml-lg-auto mt-lg-4 mt-md-4 mt-sm-4 mt-4">
                  <h2 className="title text-d-cyan pb-0 mb-md-4 mb-sm-4 mb-4">
                    Contact
                  </h2>
                  <div className="pt-lg-3 pt-md-3 pt-3 mb-lg-5 mb-md-5 mb-5">
                    <h4 className="email-heading">EMAIL</h4>
                    <a href="mailto:info@NuQinc.com" className="email-info">
                      info<b>@nuqscores</b>.com
                    </a>
                  </div>
                  <ContactForm />
                </div>
              </div>
            </div>
          </article>
        </section>
      </Layout>
    </>
  )
}

export default TheCompany
